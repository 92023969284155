<template>

<div>

	<com-title text="Assignments" />

	<com-item route="Convention.Volunteers.Confirmed" text="Confirmed" :count="$convention.count.volunteers.assignments" :force-count="true" />
	<com-item route="Convention.Volunteers.Pending" text="Pending" :count="$convention.count.volunteers.pending" :force-count="true" />

	<com-title text="Volunteers" />

	<com-item route="Convention.Volunteers.Badges" text="Assigned" :count="$convention.count.volunteers.total" :force-count="true" />
	<com-item route="Convention.Volunteers.Approvals" text="Pre-approved" :count="$convention.count.volunteers.whitelists" :force-count="true" />
	<com-item route="Convention.Volunteers.Signups" text="Sign-ups" :count="$convention.count.volunteers.signups" :force-count="true" />

	<com-title text="Setup" />

	<com-item route="Convention.Volunteers.Roles" text="Roles" :count="$convention.count.volunteers.roles" :force-count="true" />
	<com-item route="Convention.Volunteers.Settings" text="Settings" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../../panel/common/Title'),
		'com-item': () => import('./../../panel/common/Item')
	}

}

</script>

<style scoped>

</style>
